.icon {
  display: none;
}

@media screen and (max-width: 991px) {
  .icon {
    float: right;
    display: block;
    margin-bottom: 10px;
  }
  #myTopnav {
    display: none !important;
  }

  #myTopnav.open {
    position: relative;
  }
  #myTopnav.open a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  #myTopnav.open a {
    float: none;
    display: block;
    text-align: left;
  }
  .opening {
    width: 100%;
  }
}

.opening2 {
  z-index: 1000;
}
