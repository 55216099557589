.mainStartHead {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.about-head {
  font-size: 200%;
  font-weight: bolder;
  color: black;
}
.about-head2 {
  font-size: 150%;
  font-weight: bolder;
  color: black;
}
.about-text {
  font-size: 120%;
  color: black;
}
.about-img {
  width: 100%;
}
