::-webkit-scrollbar-track {
  border: 5px solid white;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #049ee3;
  border-radius: 10px;
}
